<template>
  
  <div class="space-y-4">
    <label class="block text-sm font-medium text-gray-700">
      {{ label }}
    </label>

    <div class="flex space-x-4 items-center">
      <div class="relative max-w-sm w-full">
        <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
        <svg class="w-6 h-6 text-gray-500 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
           <path fill-rule="evenodd" d="M5 5a1 1 0 0 0 1-1 1 1 0 1 1 2 0 1 1 0 0 0 1 1h1a1 1 0 0 0 1-1 1 1 0 1 1 2 0 1 1 0 0 0 1 1h1a1 1 0 0 0 1-1 1 1 0 1 1 2 0 1 1 0 0 0 1 1 2 2 0 0 1 2 2v1a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V7a2 2 0 0 1 2-2ZM3 19v-7a1 1 0 0 1 1-1h16a1 1 0 0 1 1 1v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2Zm6.01-6a1 1 0 1 0-2 0 1 1 0 0 0 2 0Zm2 0a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm6 0a1 1 0 1 0-2 0 1 1 0 0 0 2 0Zm-10 4a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm6 0a1 1 0 1 0-2 0 1 1 0 0 0 2 0Zm2 0a1 1 0 1 1 2 0 1 1 0 0 1-2 0Z" clip-rule="evenodd"/>
        </svg>

        </div>
        <input
          id="datepicker-title"
          :type="isPeriodMonth ? 'month' : 'date'"
          v-model="date"
          @change="updateDateTime"
          :max="maxDateProp ? maxDateProp.toISOString().split('T')[0] : null"
          class="bg-gray-50 border border-gray-300 text-gray-500 pl-10 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Select date"
        />
      </div>
     
      <div class="relative max-w-sm w-full" v-if="timeDate">
        <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
           <svg class="w-6 h-6 text-gray-500 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
  <path fill-rule="evenodd" d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm11-4a1 1 0 1 0-2 0v4a1 1 0 0 0 .293.707l3 3a1 1 0 0 0 1.414-1.414L13 11.586V8Z" clip-rule="evenodd"/>
</svg>

        </div>
        <input
          type="time"
          v-model="time"
          @change="updateDateTime"
          class="bg-gray-50 border border-gray-300 pl-10 text-gray-500 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        />
      </div>
    </div>

  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "DatetimeComponent",
  props: {
    value: {
         type: String, 
      default: null,
    },
    label: {
      required: true,
    },
    maxDate: {
      type: Boolean,
      default: false,
    },
    timeDate: {
      type:Boolean,
      default:true
    },
    isPeriodMonth: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      date: this.value ? this.formatDate(this.value) : null,
      time: this.value ? moment(this.value).format("HH:mm") : null,
    };
  },
  watch: {
    value(valueDatetime) {
      if (valueDatetime) {
        this.date = this.formatDate(valueDatetime);
        this.time = moment(valueDatetime).format("HH:mm");
      }
    }
  },
  computed: {
    maxDateProp() {
      const userData = JSON.parse(localStorage.getItem('session_auth'));
      const isRole = userData.role_id === 5;
      const isCreatingNewspaper = this.$route.name === "newspaperCreate";
      const isEditingNewspaper = this.$route.name === "newspaperEdit";
      if (this.maxDate && isRole && (isCreatingNewspaper || isEditingNewspaper)) {
        return new Date(new Date().setDate(new Date().getDate() + 1));
      } else {
        return null;
      }
    },
  },
  methods: {
    formatDate(date) {
      if (this.isPeriodMonth) {
        return moment(date).format("YYYY-MM");
      } else {
        return moment(date).format("YYYY-MM-DD");
      }
    },
   
    updateDateTime() {
    if (this.isPeriodMonth) {
      this.$emit("input", moment(this.date).format("YYYY-MM")); // Emit format bulan
    } else if (this.date && this.time) {
      const datetime = moment(`${this.date} ${this.time}`, "YYYY-MM-DD HH:mm").toString();
      this.$emit("input", datetime);  // Emit format tanggal dan waktu
    }
  }
  },
};
</script>
<style>
input[type="month"]::-webkit-calendar-picker-indicator,
input[type="date"]::-webkit-calendar-picker-indicator,
input[type="time"]::-webkit-calendar-picker-indicator  {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: auto;
  height: auto;
  color: transparent;
  background: transparent;
}


</style>